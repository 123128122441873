import {signupformValidation}  from "./signup"
import {signinFormValidation} from "./signin"
import { ICustomer } from "./types/Customer";
import { createApp, defineAsyncComponent } from 'vue'
import { Brand } from "./types/Brand";
import { ILicense } from './types/License';

export function mainFunction()
{
  const loginForm=document.getElementById("login_form") as Element
  const signupForm=document.getElementById("signup_form") as Element
  if(loginForm!=null && loginForm!=undefined)
  {
    signinFormValidation();
  }
  if(signupForm!=null && signupForm!=undefined)
  {
    signupformValidation();
  }

    /* Aside & Navbar: dropdowns */
  let message: string = 'Hello, TypeScript!';
  console.log(message)
  const dropDownElements: Element[]=Array.from(document.getElementsByClassName('dropdown'));

  dropDownElements.forEach(element => {
    element.addEventListener("click", e =>{
      const htmlElement= e.currentTarget as Element
      if (htmlElement.classList.contains('navbar-item')) {
        htmlElement.classList.toggle('active')
      }
      else {
        const dropdownIcon =htmlElement.getElementsByClassName("mdi")[1];
        const parNode= htmlElement.parentNode as Element;
        parNode.classList.toggle("active");
        dropdownIcon.classList.toggle("mdi-plus");
        dropdownIcon.classList.toggle("mdi-minus");
      }
    })
  });


  /* Aside Mobile toggle */
  Array.from(document.getElementsByClassName('mobile-aside-button')).forEach(el => {
    el.addEventListener('click', e => {
      const htmlElement = e.currentTarget as Element
      const dropdownIcon = htmlElement
          .getElementsByClassName('icon')[0]
          .getElementsByClassName('mdi')[0]

      document.documentElement.classList.toggle('aside-mobile-expanded')
      dropdownIcon.classList.toggle('mdi-forwardburger')
      dropdownIcon.classList.toggle('mdi-backburger')
    })
  })

  /* NavBar menu mobile toggle */

  Array.from(document.getElementsByClassName('--jb-navbar-menu-toggle')).forEach(el => {
    el.addEventListener('click', e => {
      const htmlElement = e.currentTarget as Element
      const dropdownIcon = htmlElement
          .getElementsByClassName('icon')[0]
          .getElementsByClassName('mdi')[0]
      const dataTarget:string =  htmlElement.getAttribute('data-target')!
      const dataTargetElement=document.getElementById(dataTarget) as Element;
      dataTargetElement.classList.toggle('active')
      dropdownIcon.classList.toggle('mdi-dots-vertical')
      dropdownIcon.classList.toggle('mdi-close')
    })
  })

  /* Modal: open */

  Array.from(document.getElementsByClassName('--jb-modal')).forEach(el => {
    el.addEventListener('click', e => {
      const htmlElement= e.currentTarget as Element
      const modalTarget: string = htmlElement.getAttribute('data-target')!

      const dataTargetElement=document.getElementById(modalTarget) as Element
      dataTargetElement.classList.add('active')
      document.documentElement.classList.add('clipped')
    })
  });

  // /* Modal: close */

  Array.from(document.getElementsByClassName('--jb-modal-close')).forEach(el => {
    el.addEventListener('click', e => {
      const htmlElment=e.currentTarget as Element;
      htmlElment.closest('.modal');
      htmlElment.classList.remove('active');
      document.documentElement.classList.remove('is-clipped')
    })
  })

  // /* Notification dismiss */

  Array.from(document.getElementsByClassName('--jb-notification-dismiss')).forEach(el => {
    el.addEventListener('click', e => {
      const htmlElement=e.currentTarget as Element;
      htmlElement.closest('.notification')
      htmlElement.classList.add('hidden')
    })
  })

}

export let asideMenuApp= (baseUrl: String, customerName: String, isAdmin: Boolean, brand: Brand) =>{

  return createApp({
    name:"Aside Menu Root App",
    setup() {
      return {
        baseUrl,
        customerName,
        isAdmin, 
        brand
      }
    }
  })
  .component(
    'aside-menu-root-view',
    defineAsyncComponent(() => import('@/views/AsideMenuRootView.vue'))
  ).mount('#aside_container')

}

export let dashBoardApp = (customer: ICustomer, baseUrl: string) => {

  return createApp({
    name:"Customer License Manager",
    setup() {
      return {
        customer, 
        baseUrl
      }
    }
  })
  .component(
    'dashboard-root-view',
    defineAsyncComponent({
      loader: () => import("@/views/DashboardRootView.vue")})
    )
  .mount('#dashboard_container')
}

export let customerApp = (customers: ICustomer[], baseUrl: string) => {

  return createApp({
    name:"Customer Root View",
    setup() {
      return {
        customers, 
        baseUrl
      }
    }
  })
  .component(
    'customer-root-view',
    defineAsyncComponent({
      loader: () => import("@/views/CustomerRootView.vue")})
    )
  .mount('#customer_container')
}

export let licenseApp = (baseUrl: string, licenses: ILicense[]) => {

  return createApp({
    name:"License Manager Root View",
    setup() {
      return { 
        baseUrl, 
        licenses
      }
    }
  })
  .component(
    'license-manager-root-view',
    defineAsyncComponent({
      loader: () => import("@/views/LiecsenManagerRootView.vue")})
    )
  .mount('#license-manager_container')
}

