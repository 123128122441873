import { Rules} from 'just-validate';
import JustValidate from 'just-validate'

export let signinFormValidation=() => {
  const validator = new JustValidate('#login_form',{ 
    validateBeforeSubmitting: false,
    errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          fontSize: '16px',
          color: '#dc3545',
        },
        successFieldCssClass: 'is-valid',
        successLabelStyle: {
          fontSize: '16px',
          color: '#20b418',
        },
        focusInvalidField: true,
  });
  validator
  .addField('#UserName', [
    {
      rule: Rules.Required,
      errorMessage: 'User name is required',
      
    },
    
  ])
  .addField('#Password', [
    {
      rule: Rules.Required,
      errorMessage: 'Password is required'
    },
  ])
  .onSuccess((event) =>{
      const form=document.getElementById("login_form") as HTMLFormElement
      form.submit();
    })
}
