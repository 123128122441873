import { Rules} from 'just-validate';
import JustValidate from 'just-validate'

export let signupformValidation=() => {
  const validator = new JustValidate('#signup_form');
  validator
  .addField('#Customer_FirstName', [
    {
      rule: Rules.Required,
      errorMessage: 'Firts name is required'
    },
  ])
  .addField('#Customer_LastName', [
    {
      rule: Rules.Required,
      errorMessage: 'Last name is required'
    },
  ])
  .addField('#Customer_Email', [
    {
      rule: Rules.Required,
      errorMessage: 'Last name is required'
    },
    {
      rule: Rules.Email,
      errorMessage:'Invalid email address'
    }
   
  ])
  .addField('#Customer_BusinessName', [
    {
      rule: Rules.Required,
      errorMessage: 'Business is required'
    }
  ])
  .addField('#Password', [
    {
      rule: Rules.Password
    }, 
    {
      rule: Rules.Required,
      errorMessage: 'Business is required'
    }
  ])
  .addField('#ConfirmPassword', [
    {
      rule:Rules.Required,
    },
    {
      validator: (value, fields) => {
        if (
          fields['#Password'] &&
          fields['#Password'].elem
        ) {
          const repeatPasswordValue =
            fields['#Password'].elem.value;

          return value === repeatPasswordValue;
        }

        return true;
      },
      errorMessage: 'Passwords does not match',
    },
  ])
  .onSuccess((event) =>{
    const form=document.getElementById("signup_form") as HTMLFormElement
    form.submit();
  })
}
